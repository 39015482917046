import http from '@framework/utils/http';
import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from '@framework/utils/api-endpoints';
import { CurrencyType } from '@services/mage/directory-types';

export const fetchCurrency = async (_symbol: string) => {
  const { data } = await http.get(`${API_ENDPOINTS.CURRENCIES}`);
  const result = data.filter(
    (currency: CurrencyType) => currency.symbol === _symbol,
  );

  return result[0];
};

export const useCurrencyQuery = (symbol: string) => {
  return useQuery({
    queryKey: [API_ENDPOINTS.CURRENCIES, symbol],
    queryFn: () => fetchCurrency(symbol),
  });
};
