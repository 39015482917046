export const API_ENDPOINTS = {
  LOGIN: '/login',
  REGISTER: '/register',
  LOGOUT: '/logout',
  FORGET_PASSWORD: '/forget-password',
  CATEGORIES: '/categories.json',
  DIETARY: '/dietary.json',
  BRANDS: '/brands.json',
  PRODUCTS: '/mage-products.json',
  ENGAGEMENT_RINGS: '/products/engagement-rings',
  WEDDING_BANDS: '/products/wedding-bands',
  JEWELLERIES: '/products/jewellery',
  JUSTFORYOU_PRODUCTS: '/products/just-foryou',
  GOLDS: '/products/golds',
  PRODUCT: '/products',
  PRODUCT_SEARCH: '/products/search',
  LOOSE_DIAMONDS: '/loose-diamonds/search',
  LOOSE_DIAMONDS_HISTORY: '/loose-diamonds/view-history',
  GEMSTONES: '/loose-diamonds/search',  
  RELATED_PRODUCTS: '/related_products.json',
  FLASH_SELL_PRODUCTS: '/products_flash_sell.json',
  BEST_SELLER_PRODUCTS: '/products_best_seller.json',
  BEST_SELLER_GROCERY_PRODUCTS: '/products_best_seller_grocery.json',
  POPULAR_PRODUCTS: '/products_popular.json',
  COOKIES_PRODUCTS: '/products_cookies.json',
  CHIPS_PRODUCTS: '/products_chips.json',
  POPCORN_JERKY_PRODUCTS: '/products_popcorn_jerky.json',
  FRESH_VEGETABLES_PRODUCTS: '/products_fresh_vegetables.json',
  SEARCH: '/search.json',
  ORDERS: '/sales/orders',
  ORDERS_ABANDON: '/sales/orders-abandon',
  ORDER: '/order.json',
  SALES_ORDER: '/sales/get-order',
  MAGE_SALES_ORDERS: '/mage/sales/orders',
  CUSTOMER_ORDERS: '/customers/orders',
  ORDER_STATUS: '/order-status.json',
  ADDRESS: '/customers/address',
  PAYMENT: '/payment.json',
  CONTACT: '/contact.json',
  CUSTOMERS_LISTS: '/customers/lists',
  SHOP: '/shop.json',
  SHOPS: '/shops.json',
  WISHLIST: '/wishlist',
  COUNTRIES: '/countries.json',
  CURRENCIES: '/currencies.json',
  SALES_PERSONS: '/sales_persons.json',
  TAX_RATES: '/tax-rates.json',
  GEMSTONE_SHOP_JEWELS: '/jewel_gemstone_shop.json',
  CONFIG: '/config',
  PROMOTIONS: {
    SG: '/promo-sg.json',
    MY: '/promo-my.json',
    INTL: '/promo-intl.json',
  },
  SOCIAL_POSTINGS: '/social_posting.json',
  JEWELLERY_STOCK: '/products/stock-count',
  GOLD_RATE: '/products/gold-rates',
  COLLECTIONS: '/products/collections',
};
