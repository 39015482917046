import { getCurrencyInfo } from '@contexts/store/store-utils';
import { getFormattedPrice, getPlainPrice } from '@framework/product/use-price';

export const priceDecimal = 0;

export class Format {
  static price(price: number): number {
    return Number(price.toFixed(priceDecimal));
  }

  static convertToFloat(value: any): number {
    return parseFloat(value.toFixed(priceDecimal));
  }
}

export const fmtPrice = (price: number, currencyCode: string): number => {
  const currencyData = getCurrencyInfo(currencyCode);

  const newPrice = getPlainPrice({
    baseAmount: price,
    amount: price,
    currencyCode: currencyCode,
    currencyRate: currencyData.exchangeRate,
    locale: 'en-US',
  });

  return Number(newPrice.replace(/\,/g, ''));
};

export const fmtCurrencyPrice = (price: number, currencyCode: string) => {
  const currencyData = getCurrencyInfo(currencyCode);

  const newPrice = getFormattedPrice({
    baseAmount: price,
    amount: price,
    currencyCode: currencyCode,
    currencyRate: currencyData.exchangeRate,
    locale: 'en',
  });

  return newPrice;
};

export const getTaxRate = () => {
  const currencyData = getCurrencyInfo('SGD');
  return currencyData.taxRate;
};

export const convertPrice = (price: number, currencyCode: string): number => {
    const currencyData = getCurrencyInfo(currencyCode);
    const newPrice = price * currencyData.exchangeRate;
    return Number(newPrice.toFixed(0));
}
