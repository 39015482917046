import {
  StoreCustomerInitialData,
  TCustomer,
  TCustomerAddress,
} from '@services/mage/customer-types';
import { IStoreCart, IStoreCheckout } from './store-types';

const StoreCartInitialData: IStoreCart = {
  items: [],
  isEmpty: true,
  totalItems: 0,
  totalUniqueItems: 0,
  total: 0,
};

const StoreCheckoutInitialData: IStoreCheckout = {
  totalSummary: {
    subtotal: 0,
    discountAmount: 0,
    discountCode: '',
    shipping: 0,
    tax: 0,
    total: 0,
  },
  checkoutSummary: {
    deliveryType: 'preferred-address',
    shipping: {} as TCustomerAddress,
    billing: {} as TCustomerAddress,
    shopAddress: {} as TCustomerAddress,
    paymentMethod: {
      paymentMode: '',
      payableAmount: '',
      collectionDate: '',
      remarks: '',
      salesPerson: '',
      methodTitle: '',
    },
  },
};

export const getDefaultInitialState = () => {
  return {
    customer: {} as TCustomer,
    wishlist: StoreCartInitialData,
    cart: StoreCartInitialData,
    checkout: StoreCheckoutInitialData,
    currency: 'SGD',
    language: 'en',
    storeId: 1,
    storeEvent: '',
  };
};
