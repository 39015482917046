'use client';

import { useRef } from 'react';
import { Provider, StoreType } from './store';
import { initializeStore } from './store-create';

export default function StoreProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const storeRef = useRef<StoreType>();

  if (!storeRef.current) {
    storeRef.current = initializeStore();
  }

  return <Provider value={storeRef.current}>{children}</Provider>;
}
