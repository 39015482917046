import {
  ICurrency,
  IStateCurrency,
  getDefaultCurrency,
} from '@contexts/currency/currency-store-types';
import { fetchCurrency } from '@framework/payment/get-currency';
import { getFormattedPrice, getPlainPrice } from '@framework/product/use-price';
import { TDeliveryType } from '@services/mage/checkout-types';
import { GetStoreCurrency } from '@services/mage/cms-helper';
import { TCustomerAddress } from '@services/mage/customer-types';
import { createStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { getDefaultInitialState } from './store-default';
import {
  ICheckoutSummary,
  IItem,
  IStore,
  IUpdateItemInput,
} from './store-types';
import {
  calculateSubTotal,
  calculateTotalItems,
  calculateUniqueItems,
  getCurrencyInfo,
} from './store-utils';
import { TSaleOption } from '@services/mage/catalog-product-types';
import { getTaxRate } from '@utils/format';

export interface IStoreAction {
  setStoreId: (storeId: number) => void;
  updateItem: (id: IItem['id'], item: IUpdateItemInput) => void;
  addItemToCart: (item: IItem, quantity: number) => void;
  removeItemFromCart: (id: IItem['id'], quantity: number) => void;
  removeItemPairFromCart: (pairId: string) => void;
  clearItemFromCart: (id: IItem['id']) => void;
  getItemFromCart: (id: IItem['id']) => IItem | undefined;
  isInCart: (id: number) => boolean;
  isInCartStone: (id: number) => boolean;
  isInStock: (id: IItem['id']) => boolean;
  applyDiscount: (
    discountCode: string,
    discountAmount: number,
    discountAmountRef: string,
    discountAction: string,
    appliedRuleIds: string,
  ) => void;
  updateItemDiscount: (
    sku: string,
    discountAmount: number,
    appliedRuleIds: string,
  ) => void;
  clearDiscount: () => void;
  setDeliveryMethod: (deliveryType: TDeliveryType) => void;
  addAddress: (address: TCustomerAddress) => void;
  updateAddress: (address: TCustomerAddress) => void;
  deleteAddress: (addressId: number) => void;
  setActiveAddress: (id: number) => void;
  updateShippingAddress: (shipping: TCustomerAddress) => void;
  updateBillingAddress: (billing: TCustomerAddress) => void;
  setDefaultBillingAddress: (billingId: number) => void;
  setDefaultShippingAddress: (shippingId: number) => void;
  updateShopAddress: (shopAddress: TCustomerAddress) => void;
  updatePayment: (paymentMethod: ICheckoutSummary['paymentMethod']) => void;
  updatePaymentMode: (paymentMode: string) => void;
  setPaymentMethod: (paymentMethod: ICheckoutSummary['paymentMethod']) => void;
  updateTax: (rate: number) => void;
  resetCart: () => void;
  isCartEmpty: () => boolean;
  calculateCart: () => void;
  calculateWishlist: () => void;
  addItemToWishlist: (item: IItem, quantity: number) => void;
  removeItemFromWishlist: (id: IItem['id']) => void;
  removeItemFromWishlistStone: (id: IItem['id']) => void;
  clearItemFromWishlist: (id: IItem['id']) => void;
  isWishlistEmpty: () => boolean;
  isInWishlist: (id: IItem['id']) => boolean;
  isInWishlistStone: (id: IItem['id']) => boolean;
  resetWishlist: () => void;
  setLanguage: (language: string) => void;
  setCustomer: (customer: IStore['customer']) => void;
  addCustomerAddress: (address: TCustomerAddress) => void;
  updateQuoteId: (quoteId: number) => void;
  updateWishlistId: (wishlistId: number) => void;
  updateRuleIds: (ruleIds: string) => void;
  updateDiscountAction: (discountAction: string) => void;
  updateStoreEvent: (storeEvent: string, data?: object) => void;
  updateSalesPerson: (salesPerson: string) => void;
  updateItemSaleOption: (id: IItem['id'], saleOption: TSaleOption) => void;
  resetItemDiscount: (items: IItem[]) => void;
}

export interface IStateStore extends IStore, IStoreAction, IStateCurrency {}

export function initializeStore() {
  const defaultStoreInitialState = getDefaultInitialState();

  return createStore(
    persist<IStateStore>(
      (set, get) => ({
        ...defaultStoreInitialState,
        ...getDefaultCurrency(),
        updateStoreEvent: (storeEvent: string) => set({ storeEvent }),
        setStoreId: (storeId: number) => set({ storeId }),
        setLanguage: (language: string) => set({ language }),
        updateItem: (id: IItem['id'], item: IUpdateItemInput) => {
          // update only the existing
          set((state) => ({
            cart: {
              ...state.cart,
              items: state.cart.items.map((existingItem) =>
                existingItem.id === id
                  ? { ...existingItem, ...item }
                  : existingItem,
              ),
            },
          }));

          get().calculateCart();
        },
        addItemToCart: (item: IItem, quantity: number) => {
          const itemsCount = get().cart.items.length;

          if (itemsCount >= 10) {
            return;
          }

          if (item === null) return;

          // replace the existing item
          const existingItem = get().cart.items.find(
            (existingItem) => existingItem.id === item.id,
          );

          if (existingItem) {
            set((state) => ({
              cart: {
                ...state.cart,
                items: state.cart.items.map((existingItem) =>
                  existingItem.id === item.id
                    ? {
                        ...existingItem,
                        quantity: existingItem.quantity! + quantity,
                      }
                    : existingItem,
                ),
              },
            }));
          } else {
            set((state) => ({
              cart: {
                ...state.cart,
                items: [...state.cart.items, { ...item, quantity }],
              },
            }));
          }

          get().calculateCart();
          get().updateStoreEvent('add-to-cart');
        },
        removeItemFromCart: (id: IItem['id'], quantity: number = 1) => {
          // get item quantity
          const item = get().cart.items.find(
            (existingItem) => existingItem.id === id,
          );

          // quantity is 0, remove the item
          if (item.quantity === 1) {
            set((state) => ({
              cart: {
                ...state.cart,
                items: state.cart.items.filter(
                  (existingItem) => existingItem.id !== id,
                ),
              },
            }));
          } else {
            set((state) => ({
              cart: {
                ...state.cart,
                items: state.cart.items.map((existingItem) =>
                  existingItem.id === id
                    ? {
                        ...existingItem,
                        quantity: existingItem.quantity! - quantity,
                      }
                    : existingItem,
                ),
              },
            }));
          }
          get().calculateCart();
          get().updateStoreEvent('remove-from-cart', { id: id });
        },

        removeItemPairFromCart: (pairId: string) => {
          set((state) => ({
            cart: {
              ...state.cart,
              items: state.cart.items.filter(
                (existingItem) => existingItem.pairId !== pairId,
              ),
            },
          }));
          get().calculateCart();
        },

        clearItemFromCart: (id: IItem['id']) => {
          set((state) => ({
            cart: {
              ...state.cart,
              items: state.cart.items.filter(
                (existingItem) => existingItem.id !== id,
              ),
            },
          }));
          get().calculateCart();
          get().updateStoreEvent('clear-item-cart', { id: id });
        },
        getItemFromCart: (id: IItem['id']) =>
          get().cart.items.find((existingItem) => existingItem.id === id),
        isInCart: (id: number) =>
          get().cart.items.some(
            (existingItem) => existingItem.productId === id,
          ),
        isInCartStone: (id: number) =>
          get().cart.items.some(
            (existingItem) =>
              existingItem.productId === id &&
              existingItem.productType === 'stone',
          ),
        isInStock: (id: IItem['id']) =>
          get().cart.items.some(
            (existingItem) =>
              existingItem.id === id &&
              existingItem.quantity! < existingItem.stock!,
          ),
        applyDiscount: (
          discountCode: string,
          discountAmount: number,
          discountAmountRef: string,
          discountAction: string,
          appliedRuleIds: string,
        ) => {
          set((state) => ({
            checkout: {
              ...state.checkout,
              totalSummary: {
                ...state.checkout.totalSummary,
                discountCode,
                discountAmount,
                discountAmountRef,
                appliedRuleIds,
                discountAction,
              },
            },
          }));

          get().calculateCart();
        },

        updateItemDiscount: (
          sku: string,
          discountAmount: number,
          appliedRuleIds: string,
        ) => {
          set((state) => ({
            cart: {
              ...state.cart,
              items: state.cart.items.map((existingItem) =>
                existingItem.sku.toLowerCase() === sku.toLowerCase()
                  ? {
                      ...existingItem,
                      discountOption: {
                        discountAmount,
                        appliedRuleIds,
                      },
                    }
                  : existingItem,
              ),
            },
          }));
          get().calculateCart();
        },

        clearDiscount: () => {
          set((state) => ({
            checkout: {
              ...state.checkout,
              totalSummary: {
                ...state.checkout.totalSummary,
                discountCode: '',
                discountAmount: 0,
                appliedRuleIds: '',
                discountAction: '',
                discountAmountRef: '',
              },
            },
          }));

          get().calculateCart();
        },
        setDeliveryMethod: (deliveryType: TDeliveryType) =>
          set((state) => ({
            checkout: {
              ...state.checkout,
              checkoutSummary: {
                ...state.checkout.checkoutSummary,
                deliveryType,
              },
            },
          })),
        addAddress: (address: TCustomerAddress) =>
          set((state) => ({
            customer: {
              ...state.customer,
              addresses: [...state.customer.addresses, address],
            },
          })),
        updateAddress: (address: TCustomerAddress) =>
          set((state) => ({
            customer: {
              ...state.customer,
              addresses: state.customer.addresses.map((existingAddress) =>
                existingAddress.id === address.id ? address : existingAddress,
              ),
            },
          })),
        deleteAddress: (addressId: number) => {
          set((state) => ({
            customer: {
              ...state.customer,
              addresses: state.customer.addresses.filter(
                (existingAddress) => existingAddress.id !== addressId,
              ),
            },
          }));
        },
        setActiveAddress: (id: number) =>
          set((state) => ({
            customer: {
              ...state.customer,
              addresses: state.customer.addresses.map((existingAddress) => ({
                ...existingAddress,
                active: existingAddress.id === id,
              })),
            },
          })),
        updateShippingAddress: (shipping: TCustomerAddress) =>
          set((state) => ({
            checkout: {
              ...state.checkout,
              checkoutSummary: {
                ...state.checkout.checkoutSummary,
                shipping,
              },
            },
          })),
        updateBillingAddress: (billing: TCustomerAddress) => {
          set((state) => ({
            checkout: {
              ...state.checkout,
              checkoutSummary: {
                ...state.checkout.checkoutSummary,
                billing,
              },
            },
          }));
          get().calculateCart();
        },

        setDefaultBillingAddress: (billingId: number) => {
          // update default customer billing address

          set((state) => ({
            customer: {
              ...state.customer,
              default_billing: billingId.toString(),
              addresses: state.customer.addresses.map((existingAddress) => {
                if (existingAddress.id === billingId) {
                  return { ...existingAddress, default_billing: true };
                }
                return { ...existingAddress, default_billing: false };
              }),
            },
          }));
        },

        setDefaultShippingAddress: (shippingId: number) => {
          // update default customer shipping address

          set((state) => ({
            customer: {
              ...state.customer,
              default_shipping: shippingId.toString(),
              addresses: state.customer.addresses.map((existingAddress) => {
                if (existingAddress.id === shippingId) {
                  return { ...existingAddress, default_shipping: true };
                }
                return { ...existingAddress, default_shipping: false };
              }),
            },
          }));
        },

        updateShopAddress: (shopAddress: TCustomerAddress) =>
          set((state) => ({
            checkout: {
              ...state.checkout,
              checkoutSummary: {
                ...state.checkout.checkoutSummary,
                shopAddress,
              },
            },
          })),
        updatePayment: (paymentMethod: ICheckoutSummary['paymentMethod']) =>
          set((state) => ({
            checkout: {
              ...state.checkout,
              checkoutSummary: {
                ...state.checkout.checkoutSummary,
                paymentMethod,
              },
            },
          })),
        setPaymentMethod: (paymentMethod: ICheckoutSummary['paymentMethod']) =>
          set((state) => ({
            checkout: {
              ...state.checkout,
              checkoutSummary: {
                ...state.checkout.checkoutSummary,
                paymentMethod: paymentMethod,
              },
            },
          })),
        updatePaymentMode: (paymentMode: string) => {
          set((state) => ({
            checkout: {
              ...state.checkout,
              checkoutSummary: {
                ...state.checkout.checkoutSummary,
                paymentMethod: {
                  ...state.checkout.checkoutSummary.paymentMethod,
                  paymentMode: paymentMode,
                },
              },
            },
          }));
        },
        updateTax: (rate: number) => {
          set((state) => ({
            checkout: {
              ...state.checkout,
              totalSummary: {
                ...state.checkout.totalSummary,
                tax: rate,
              },
            },
          }));
          get().calculateCart();
        },
        resetCart: () => {
          set((state) => ({
            cart: {
              ...state.cart,
              items: [],
              isEmpty: true,
              totalItems: 0,
              totalUniqueItems: 0,
              total: 0,
            },
            checkout: {
              ...state.checkout,
              totalSummary: {
                subtotal: 0,
                discountAmount: 0,
                discountCode: '',
                shipping: 0,
                tax: 0,
                total: 0,
              },
            },
          }));

          get().calculateCart();
          get().updateStoreEvent('reset-cart');
        },
        isCartEmpty: () => get().cart.items.length == 0,
        calculateCart: () => {
          /**
           * Calculation sequence:
           * 1. Calculate the subtotal
           * 2. Calculate the discount
           * 3. Calculate the tax
           * 4. Calculate the grand total
           */
          const taxCountry = 'SG';
          const taxRate = getTaxRate();
          let canTax = false;          

          const checkoutSummary = get().checkout.checkoutSummary;

          // tax based on shipping address
          let countryId = checkoutSummary?.shipping?.country_id ?? taxCountry;

          if (countryId?.toUpperCase() === taxCountry) {
            canTax = true;
          }

          if (checkoutSummary.deliveryType === 'store-pickup') {

                countryId = checkoutSummary?.shopAddress?.country_id ?? taxCountry;
                canTax = true;

                if (countryId?.toUpperCase() === 'MY') {
                    canTax = false;
                }
          }          

          if (checkoutSummary.deliveryType === 'preferred-address') {

                countryId = checkoutSummary?.shipping?.country_id ?? taxCountry;
                canTax = false;
            
                if (countryId?.toUpperCase() === taxCountry) {
                    canTax = true;
                }

          }

          const total = calculateSubTotal(get().cart.items);
          const discountAmount = Number(
            get().checkout.totalSummary.discountAmount ?? 0,
          );

          // compute by fixed default
          const subtotal = Number(total) - Number(discountAmount);
          // tax calculation
          const calcTaxSG = Number(subtotal) * Number(taxRate);
          const calcTax = canTax ? calcTaxSG : 0;

          set((state) => ({
            checkout: {
              ...state.checkout,
              totalSummary: {
                ...state.checkout.totalSummary,
                tax: Number(calcTax.toFixed(0)) ?? 0,
              },
            },
          }));

          const grandTotal = subtotal + calcTax;

          const totalItems = calculateTotalItems(get().cart.items)

          set((state) => ({
            cart: {
              ...state.cart,
              isEmpty: state.cart.items.length == 0,
              total: Number(grandTotal.toFixed(0)),
              totalItems: totalItems,
              totalUniqueItems: calculateUniqueItems(state.cart.items),
            },
            checkout: {
              ...state.checkout,
              totalSummary: {
                ...state.checkout.totalSummary,
                subtotal: Number(total.toFixed(0)),
                total: Number(grandTotal.toFixed(0)),
              },
            },
          }));
        },

        addItemToWishlist: (item: IItem, quantity: number) => {
          const existingItem = get().wishlist.items.find(
            (existingItem) => existingItem.id === item.id,
          );

          if (!existingItem) {
            set((state) => ({
              wishlist: {
                ...state.wishlist,
                items: [...state.wishlist.items, { ...item, quantity }],
              },
            }));
          }

          get().calculateWishlist();
        },
        /**
         * Remove item from wishlist by item id
         * @param id
         */
        removeItemFromWishlist: (id: IItem['id']) => {
          set((state) => ({
            wishlist: {
              ...state.wishlist,
              items: state.wishlist.items.filter(
                (existingItem) => existingItem.productId !== id,
              ),
            },
          }));
          get().calculateWishlist();
        },
        /**
         * Remove item from wishlist by stone product id
         * @param id
         */
        removeItemFromWishlistStone: (id: IItem['id']) => {
          set((state) => ({
            wishlist: {
              ...state.wishlist,
              items: state.wishlist.items.filter(
                (existingItem) => existingItem.productId !== id,
              ),
            },
          }));
          get().calculateWishlist();
        },
        clearItemFromWishlist: (id: IItem['id']) => {
          set((state) => ({
            wishlist: {
              ...state.wishlist,
              items: state.wishlist.items.filter(
                (existingItem) => existingItem.id !== id,
              ),
            },
          }));
          get().calculateWishlist();
        },

        isWishlistEmpty: () => get().wishlist.items.length == 0,
        isInWishlist: (id: number) =>
          get().wishlist.items.some(
            (existingItem) => existingItem.productId === id,
          ),
        isInWishlistStone: (id: number) =>
          get().wishlist.items.some(
            (existingItem) =>
              existingItem.productId === id &&
              existingItem.productType === 'stone',
          ),
        resetWishlist: () => {
          set((state) => ({
            wishlist: {
              ...state.wishlist,
              items: [],
            },
          }));
          get().calculateWishlist();
        },
        calculateWishlist() {
          set((state) => ({
            wishlist: {
              ...state.wishlist,
              isEmpty: state.wishlist.items.length == 0,
              total: get().wishlist.items.length,
              totalItems: calculateTotalItems(state.wishlist.items),
              totalUniqueItems: calculateUniqueItems(state.wishlist.items),
            },
          }));
        },
        setCurrency: (currency: ICurrency['currency']) => {
          set({ currency: currency });
          get().calculateCart();
        },
        getCurrency: () => {
          const currency = fetchCurrency(
            get().currency.symbol?.toLocaleUpperCase(),
          );
          currency.then((res: ICurrency['currency']) => {
            set({ currency: res });
          });
        },
        formatPrice: (price: number) => {
          const currency = get().currency;
          const newPrice = getFormattedPrice({
            baseAmount: price,
            amount: price,
            currencyCode: currency.symbol,
            currencyRate: currency.rate,
            locale: get().language,
          });

          return newPrice;
        },
        formatPriceFromSymbol: (currencyCode: string, price: number) => {
          // get the currency rate from currency code
          const storeCurrency = GetStoreCurrency(currencyCode);
          const currencyData = getCurrencyInfo(storeCurrency);

          const newPrice = getFormattedPrice({
            baseAmount: price,
            amount: price,
            currencyCode: storeCurrency,
            currencyRate: currencyData.exchangeRate,
            locale: get().language,
          });

          return newPrice;
        },
        formatPriceFromCurrency: (currencyCode: string, price: number) => {
          const currencyData = getCurrencyInfo(currencyCode);          

          const newPrice = getFormattedPrice({
            baseAmount: price,
            amount: price,
            currencyCode: currencyCode,
            currencyRate: currencyData.exchangeRate,
            locale: get().language,
          });

          return newPrice;
        },
        formatPlainPrice: (currencyCode: string, price: number) => {
          const currencyData = getCurrencyInfo(currencyCode);

          const newPrice = getPlainPrice({
            baseAmount: price,
            amount: price,
            currencyCode: currencyCode,
            currencyRate: currencyData.exchangeRate,
            locale: get().language,
          });

          return newPrice;
        },
        setCustomer: (customer: IStore['customer']) =>
          set({ customer: customer }),
        updateQuoteId: (quoteId: number) =>
          set((state) => ({
            cart: {
              ...state.cart,
              quoteId,
            },
          })),
        addCustomerAddress: (address: TCustomerAddress) => {
          const existingAddress = get().customer.addresses.find(
            (existingAddress) => existingAddress.id === address.id,
          );

          if (!existingAddress) {
            set((state) => ({
              customer: {
                ...state.customer,
                addresses: [...state.customer.addresses, address],
              },
            }));
          } else {
            set((state) => ({
              customer: {
                ...state.customer,
                addresses: state.customer.addresses.map((existingAddress) =>
                  existingAddress.id === address.id ? address : existingAddress,
                ),
              },
            }));
          }
        },
        updateWishlistId: (wishlistId: number) =>
          set((state) => ({
            cart: {
              ...state.cart,
              wishlistId,
            },
          })),
        updateRuleIds: (ruleIds: string) =>
          set((state) => ({
            checkout: {
              ...state.checkout,
              totalSummary: {
                ...state.checkout.totalSummary,
                appliedRuleIds: ruleIds,
              },
            },
          })),
        updateDiscountAction: (discountAction: string) =>
          set((state) => ({
            checkout: {
              ...state.checkout,
              totalSummary: {
                ...state.checkout.totalSummary,
                discountAction: discountAction,
              },
            },
          })),
        updateSalesPerson: (salesPerson: string) =>
          set((state) => ({
            // update the sales person
            checkout: {
              ...state.checkout,
              checkoutSummary: {
                ...state.checkout.checkoutSummary,
                paymentMethod: {
                  ...state.checkout.checkoutSummary.paymentMethod,
                  salesPerson: salesPerson,
                },
              },
            },
          })),

        updateItemSaleOption: (id: IItem['id'], saleOption: TSaleOption) => {
          set((state) => ({
            cart: {
              ...state.cart,
              items: state.cart.items.map((existingItem) =>
                existingItem.id === id
                  ? {
                      ...existingItem,
                      saleOption,
                    }
                  : existingItem,
              ),
            },
          }));
        },

        resetItemDiscount: (items: IItem[]) => {
          set((state) => ({
            cart: {
              ...state.cart,
              items: items.map((existingItem) => ({
                ...existingItem,
                discountOption: {
                  discountAmount: 0,
                  appliedRuleIds: '',
                },
              })),
            },
          }));
        },
      }),
      {
        name: 'jwl-store',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  );
}
