export type TCurrency = {
  rate: number;
  symbol: string;
  taxRate: number;
  exchangeRate?: number;
};

export interface ICurrency {
  currency: TCurrency;
}

export interface ICurrencyAction {
  setCurrency: (currency: ICurrency['currency']) => void;
  getCurrency: () => void;
  formatPrice: (price: number) => string;
  formatPriceFromSymbol: (currencyCode: string, price: number) => string;
  formatPriceFromCurrency: (currencyCode: string, price: number) => string;
  formatPlainPrice: (currencyCode: string, price: number) => string;
}

export const getDefaultCurrency = () => {
  return {
    currency: {
      rate: 1,
      symbol: 'SGD',
      taxRate: process.env['GST_RATE'] ?? 0.09,
      exchangeRate: 1,
    } as TCurrency,
  };
};

export interface IStateCurrency extends ICurrency, ICurrencyAction {}
