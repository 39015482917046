import { siteSettings } from '@settings/site-settings';
import { IItem } from './store-types';


export const calculateItemTotals = (items: IItem[]) =>
  items.map((item) => ({
    ...item,
    itemTotal: item.price * item.quantity!,
  }));

export const calculateSubTotal = (items: IItem[]) => {
  return items.reduce(
    (total, item) =>
      Number(total) +
      Number(item.quantity) *
        (Number(item.price) +
          Number(item.options?.ringSize?.price ?? 0) +
          Number(item?.options?.engrave?.price ?? 0)),
    0,
  );
};

export const calculateTotalItems = (items: IItem[]) =>
  items.reduce((sum, item) => sum + item?.quantity!, 0);

export const calculateUniqueItems = (items: IItem[]) => items.length;

export const getCurrencyInfo = (currencyCode: string) => {
  const currencyData = siteSettings.site_header.currencyMenu;
  return currencyData.find(
    (value: any) => value.value.toLowerCase() === currencyCode.toLowerCase(),
  );
};
